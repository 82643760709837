exports.apod = process.env.REACT_APP_APOD;
exports.cloudFunctions = {
  createUser: process.env.REACT_APP_CREATE_USER,
  emailOTP: process.env.REACT_APP_EMAIL_OTP,
  verifyOTP: process.env.REACT_APP_VERIFY_OTP,
  checkUser: process.env.REACT_APP_CHECK_USER,
  saveUserScores: process.env.REACT_APP_SAVE_USER_SCORES,
  getUserScores: process.env.REACT_APP_GET_USER_SCORES,
  getAllUserScores: process.env.REACT_APP_GET_ALL_USER_SCORES,
  saveSchool: process.env.REACT_APP_SAVE_SCHOOL,
  getSchools: process.env.REACT_APP_GET_SCHOOLS,
  getDataForAdmins: process.env.REACT_APP_GET_DATA_FOR_ADMINS,
};
exports.firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID,
};
